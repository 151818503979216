import React from "react";
import "./BottomSection.css";
import Feature1Image from "./feature1.png";
import Feature2Image from "./feature2.png";
import Feature3Image from "./feature3.png";
import Feature4Image from "./feature4.png";
import Feature1ImageMobile from "./feature1Mobile.png";
import Feature2ImageMobile from "./feature2Mobile.png";
import Feature3ImageMobile from "./feature3Mobile.png";
import Feature4ImageMobile from "./feature4Mobile.png";



const BottomSection = () => {
  return (
    <div className="features-container">
      <div className="feature-container feature-container-1">
        <div className="feature-content">
          <div className="feature-tag">
            <div className="feature-tag-text"> Property Management</div>
          </div>
          <div className="feature-title">
            Unified Property Management: Seamlessly Integrate with Airbnb,
            Booking.com, and More{" "}
          </div>
          <div className="feature-description">
            Experience interoperable property management by integrating all your
            tools into onesimple, efficient dashboard{" "}
          </div>
        </div>

        <div className="feature-image">

        <picture>
            <source media="(min-width: 768px)" srcSet={Feature1Image} />
            <img src={Feature1ImageMobile} alt="Feature 1" />
          </picture>
        </div>
      </div>

      <div className="feature-container feature-container-2">
        <div className="feature-image">    <picture>
            <source media="(min-width: 768px)" srcSet={Feature2Image} />
            <img src={Feature2ImageMobile} alt="Feature 2" />
          </picture></div>
        <div className="feature-content">
          <div className="feature-tag">
            <div className="feature-tag-text"> Payment Options</div>
          </div>
          <div className="feature-title">
            Flexible Payment Options: Local and International Currencies Welcome{" "}
          </div>
          <div className="feature-description">
            Accommodate a global clientele with versatile payment solutions
            tailored for both Local and international transactions.{" "}
          </div>
        </div>
      </div>

      <div className="feature-container feature-container-3">
        <div className="feature-content">
          <div className="feature-tag">
            <div className="feature-tag-text"> Bookings</div>
          </div>
          <div className="feature-title">
            Seamless Direct Booking: Elevate Your Guest’s Experience{" "}
          </div>
          <div className="feature-description">
            Manage private bookings professionally. From reservation to payment,
            ensure your guests enjoy a smooth and hassle-free experience{" "}
          </div>
        </div>
        <div className="feature-image"> <picture>
            <source media="(min-width: 768px)" srcSet={Feature3Image} />
            <img src={Feature3ImageMobile} alt="Feature 3" />
          </picture></div>
      </div>


      <div className="feature-container feature-container-4">
        <div className="feature-content">
          <div className="feature-tag">
            <div className="feature-tag-text"> Data Analysis</div>
          </div>
          <div className="feature-title">
            Data-Driven Insights: Your Business at a Glance{" "}
          </div>
          <div className="feature-description">
            Make informed decisions with comprehensive data on occupancy rates,
            revenue,and property status, all available in an easy-to-read
            format.{" "}
          </div>
        </div>
        <div className="feature-image"><picture>
            <source media="(min-width: 768px)" srcSet={Feature4Image} />
            <img src={Feature4ImageMobile} alt="Feature 4" />
          </picture></div>
      </div>
    </div>
  );
};

export default BottomSection;
