import React from 'react';
import './BottomBanner.css';


const BottomBanner =({ openModal }) => {
    return (
        <div className="outer-box">
        <div className="bottom-banner-container">
            <div className="bottom-banner-flexbox">
                <div className="cta-heading">
                    <p>Book a Demo</p>
                </div>
                <div className="cta-tagline">
                    <p>All you need is an idea. We handle the rest.</p>
                </div>
                <button className="white-cta-button" onClick={openModal}>
                      
                          <span> Request Demo</span> 
                        
                    </button>
            </div>
        </div>
        </div>
    );
}

export default BottomBanner;
