import React, { useState } from 'react';

const Waitlist = () => {
    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Location: '',
        Phone_Number: '',
        Portfolio_Size: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("https://api.apispreadsheets.com/data/eZ30fBVzLiPh36jk/", {
            method: "POST",
            body: JSON.stringify({ "data": formData }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => {
            if (res.status === 201) {
                // SUCCESS
                console.log("Data submitted successfully");
                // You can clear the form or give any success message or redirection here
            } else {
                // ERROR
                console.error("Error submitting form");
                // Handle form submission error here
            }
        }).catch(error => {
            // Handle any network error here
            console.error("Network error:", error);
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input type="text" name="Name" placeholder="Name" value={formData.Name} onChange={handleChange} />
                <input type="email" name="Email" placeholder="Email" value={formData.Email} onChange={handleChange} />
                <input type="text" name="Location" placeholder="Location" value={formData.Location} onChange={handleChange} />
                <input type="text" name="Phone_Number" placeholder="Phone Number" value={formData.Phone_Number} onChange={handleChange} />
                <input type="text" name="Portfolio_Size" placeholder="Portfolio Size" value={formData.Portfolio_Size} onChange={handleChange} />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default Waitlist;
