import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import Waitlist from './Waitlist';

function App() {
  return (
    <Router>
        <div className="App">
            <Routes>
                <Route exact path="/" element={<LandingPage/>} />
                <Route exact path="/waitlist" element={<Waitlist/>} />
                
            </Routes>
        </div>
    </Router>
);
}

export default App;
