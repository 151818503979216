import React, { useState }  from "react";
import "./App.css";
// import ReekaLogo from './Reeka.svg';
// import Background from './Background.svg';
import HeroSection from "./HeroSection";
import Navbar from "./NavBar";
import BottomSection from "./BottomSection";
import BottomBanner from "./BottomBanner";
import Footer from "./Footer";
import DemoModal from "./DemoModal";
  

const LandingPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <div className="top-container">
          {/* Landing Page Content*/}
        {/* <img src={ReekaLogo} alt="Reeka Logo" className="logo"/> */}
        {/* <img src={Background} alt="Background"/> */}
        <Navbar openModal={() => setModalOpen(true)} />
        <div>
          <HeroSection openModal={() => setModalOpen(true)} />
        </div>
      </div>

      <div className="bottom-container">
        <BottomSection />
      </div>
      <BottomBanner openModal={() => setModalOpen(true)} />
      <Footer />

      <DemoModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};

export default LandingPage;
