import React from 'react';
import './SuccessModal.css';
import SuccessIcon from "./successIcon.svg"

const SuccessModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

      // Prevent clicks within the modal content from closing the modal
      const handleModalContentClick = (e) => {
        e.stopPropagation();
    };

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="success-modal" onClick={handleModalContentClick}>
        {/* Top part with illustration */}
        <div className="success-top">
          <img src={SuccessIcon} alt="Success" style={{ width: '154px', height: '129px' }} />
        </div>

        {/* Bottom part with text */}
        <div className="success-bottom">
          <div className="thank-you-container">
            <div className="thank-you-text">Thank You!</div>
            <div className="supporting-text">We have received you demo request. We will reach out shortly</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
