import React from 'react';
import './Footer.css'; // Assuming you will create a CSS file for styles

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="inner-footer-container">
                <div className="copyright-text">
                    <p>Copyright © 2024 Reeka</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
