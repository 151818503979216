import React from 'react';
import './NavBar.css';
// import { Link } from 'react-router-dom';

const Navbar = ({ openModal }) => {
    return (
        <div className="navbar">
            <div className="logo">Reeka</div>
            <button className="navbar-cta-button" onClick={openModal}>
                <span className="cta-text">Request Demo</span>
            </button>
        </div>
    );
};

export default Navbar;
