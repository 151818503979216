import React from "react";
import "./HeroSection.css";
import RightArrowIcon from "./RightArrowIcon.svg";
import HeroImage from "./HeroImage.svg";
import HeroImageMobile from "./heroImageMobile.png"

const HeroSection = ({ openModal }) => {
  return (
    <div className="hero-container">
      <div className="hero-text">
        Rental Management That Elevates Your Business
      </div>
      <div className="tagline">
        Automate, Optimize, and Grow Your Short Term Rental Operations
        with Ease
      </div>

      <button className="cta-button" onClick={openModal}>
        <span className="cta-text">Request Demo</span>
        <div className="icon-container">
          <img src={RightArrowIcon} alt="rightarrowicon" />
        </div>
      </button>

      <div className="hero-image">
      <picture>
          <source media="(min-width: 768px)" srcSet={HeroImage} />
          <img src={HeroImageMobile} alt="Hero" />
        </picture>
      </div>
    </div>
  );
};

export default HeroSection;
